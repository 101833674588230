export const apiEndpoints = {
  MAGIC_LOGIN: {
    path: '/auth/login/magicLink',
    method: 'POST',
  },
  PUBLIC_BOOK_APPOINTMENT: {
    path: '/auth/login/web/magicLink',
    method: 'POST',
  },
  GET_REFRESH_TOKEN: {
    path: '/auth/token/refresh',
    method: 'GET',
  },
  GET_NEXT_CONTENT_BLOCK: {
    path: '/conversation/processUserResponse',
    method: 'POST',
  },
  GET_NEXT_CONTENT_BLOCK_FULL_PAGE: {
    path: '/conversation/chatbots/processFullPageResponse',
    method: 'POST',
  },
  // appointments
  GET_APPOINTMENTS: {
    path: '/v2/scheduling/appointment/search',
    method: 'GET',
  },
  GET_AVAILABLE_SLOTS: {
    path: '/v2/scheduling/schedule/masterSchedule',
    method: 'GET',
  },
  GET_ALL_UNIQUE_SERVICES: {
    path: '/v2/scheduling/service/unique',
    method: 'GET',
  },
  CREATE_APPOINTMENT_REQUEST: {
    path: '/v2/scheduling/appointment',
    method: 'POST',
  },
  GET_SPECIFIC_SERVICE_PUBLIC: {
    path: '/v2/scheduling/service/public/{serviceId}',
    method: 'GET',
  },
  ACCEPT_APPOINTMENT: {
    path: '/v2/scheduling/appointment/{appointmentId}',
    method: 'PATCH',
  },
  PATCH_APPOINTMENT: {
    path: '/v2/scheduling/appointment/{appointmentId}',
    method: 'PATCH',
  },
  CREATE_APPOINTMENT_WITH_URL_FLOW: {
    path: '/v2/scheduling/appointment/url-flow',
    method: 'POST',
  },
  ACCEPT_PUBLIC_APPOINTMENT: {
    path: '/v2/scheduling/public/accept-appointment',
    method: 'POST',
  },
  // providers
  GET_PROVIDERS: {
    path: '/profile/provider/all',
    method: 'POST',
  },
  GET_PROVIDER: {
    path: '/profile/provider/{providerId}',
    method: 'GET',
  },
  GET_PROVIDER_PUBLIC: {
    path: '/profile/provider/web/{providerId}',
    method: 'GET',
  },
  // states
  GET_STATES: {
    path: '/report-service-node/states/simple',
    method: 'GET',
  },
  GET_PAYERS_BY_STATE: {
    path: '/report-service-node/payers/{stateId}',
    method: 'GET',
  },
  GET_PAYERS_BY_STATENAME: {
    path: '/report-service-node/payers/by-state-name/{stateName}',
    method: 'GET',
  },
  // schedule
  GET_SYSTEM_SERVICES: {
    path: '/scheduling/schedule/getAllSystemServices',
    method: 'GET',
  },
  GET_PUBLIC_ALL_SERVICES: {
    path: '/scheduling/schedule/web/getAllServices',
    method: 'GET',
  },
  GET_PROVIDER_SERVICES_BY_ID: {
    path: '/v2/scheduling/service/providerServices',
    method: 'GET',
  },
  GET_PROVIDER_SCHEDULE: {
    path: '/scheduling/schedule',
    method: 'GET',
  },
  JOIN_APPOINTMENT: {
    path: '/v2/scheduling/appointment/{appointmentId}',
    method: 'PATCH',
  },
  COMPLETE_APPOINTMENT: {
    path: '/v2/scheduling/appointment/{appointmentId}',
    method: 'PATCH',
  },
  UPDATE_TELEHEALTH_FEEDBACK: {
    path: '/v2/scheduling/appointment/{appointmentId}',
    method: 'PATCH',
  },

  // conversation
  GET_CONVERSATIONS: {
    path: '/conversation/conversation/list',
    method: 'GET',
  },
  ASSIGN_CONVERSATION: {
    path: '/conversation/conversation/assign',
    method: 'POST',
  },
  RESTART_CHATBOT: {
    path: '/conversation/conversation/{contactId}/restart',
    method: 'POST',
  },
  GET_CHANNEL_URL: {
    path: '/conversation/liveChat/{connectionId}/channelUrl',
    method: 'GET',
  },
  CHATBOT_DETAILS: {
    path: '/conversation/chatbots/{userId}',
    method: 'GET',
  },
  GET_CHAT_TOKEN: {
    path: '/conversation/token/generate',
    method: 'POST',
  },
  GET_CHAT_BOT_HISTORY: {
    path: '/conversation/chat/history',
    method: 'GET',
  },

  //profile
  GET_PROFILE: {
    path: '/profile/profile',
    method: 'GET',
  },
  UPDATE_PROFILE: {
    path: '/profile/profile/update',
    method: 'POST',
  },
  UPLOAD_PROFILE_IMAGE: {
    path: '/profile/provider/uploadImage',
    method: 'POST',
  },
  UPDATE_ID_CARD: {
    path: '/profile/profile/idCard',
    method: 'PATCH',
  },
  USER_ACTIVITY: {
    path: '/audit/getUserActivity/{userId}',
    method: 'GET',
  },
  JOINED_GROUPS: {
    path: '/profile/groups/joined/{userId}',
    method: 'GET',
  },
  REFRESH_DIRECTLINE_TOKEN: {
    path: '/profile/roaster/token/refresh',
    method: 'POST',
  },
  EDUCATION_VIEWED: {
    path: '/profile/education/{entryId}/viewed',
    method: 'POST',
  },
  BOOKMARK_EDUCATIONAL_CONTENT: {
    path: '/profile/education/{slug}/bookmark',
    method: 'POST',
  },
  GET_MARKED_EDUCATIONAL_CONTENT: {
    path: '/profile/education/markedSlugs',
    method: 'GET',
  },
  MARK_CONTENT_AS_COMPLETED: {
    path: '/profile/education/{slug}/markAsComplete',
    method: 'POST',
  },

  // profile/connections
  GET_CONNECTIONS: {
    path: '/profile/connections',
    method: 'GET',
  },

  GET_CARE_TEAM_MEMBERS: {
    path: '/profile/connections/careTeam/{userId}',
    method: 'GET',
  },
  ARCHIVE_CONNECTION: {
    path: '/profile/connections/archive/{connectionId}',
    method: 'POST',
  },

  // report-service-node/invoices
  GET_INVOICES: {
    path: 'report-service-node/invoices',
    method: 'POST',
  },
  // report-service-node/insurances
  CREATE_INSURANCE_PROFILE: {
    path: '/report-service-node/insurances/userprofile',
    method: 'POST',
  },
  GET_INSURANCE_INFO: {
    path: 'report-service-node/insurances/userprofile/insurance-info/{userId}',
    method: 'GET',
  },
  UPDATE_INSURANCE_INFO: {
    path: 'report-service-node/insurances/userprofile/insurance-info/{userId}',
    method: 'PUT',
  },
  UPDATE_PUBLIC_INSURANCE_INFO: {
    path: 'report-service-node/insurances/userprofile/{userId}',
    method: 'PATCH',
  },
  // billing/payments

  PAY_INVOICE: {
    path: '/billing/payments/invoicePayment',
    method: 'POST',
  },
  GET_PATIENT_INSURANCE_PROFILE: {
    path: '/billing/billingProfile',
    method: 'GET',
  },
  GET_CARDS: {
    path: '/billing/payments/cards/list',
    method: 'GET',
  },
  DELETE_CARD: {
    path: '/billing/payments/cards/{cardId}',
    method: 'DELETE',
  },
  ADD_PAYMENT_CARD: {
    path: '/billing/payments/cards/add',
    method: 'POST',
  },
  PAY_FOR_APPOINTMENT_BY_CARD: {
    path: '/billing/payments/capture/appointment/{appointmentId}',
    method: 'POST',
  },
  PAY_FOR_APPOINTMENT_BY_WALLET: {
    path: '/billing/wallet/generic/pay',
    method: 'POST',
  },
  GET_CHARGE_AMOUNT_WITH_ACCOUNTID: {
    path: '/billing/payments/chargeExpectedInfo/{patientUserAccountId}/{serviceId}',
    method: 'GET',
  },
  START_OR_JOIN_CALL: {
    path: '/profile/group/{channelUrl}/startOrJoinCall',
    method: 'POST',
  },
  FETCH_INSURANCE: {
    path: '/report-service-node/payers/by-state-name/{state}',
    method: 'GET',
  },
  VERIFY_INSURANCE: {
    path: '/report-service-node/soharhealth/entry/{userAccountId}',
    method: 'POST',
  },
  CHECK_MEMBER_DETAILS: {
    path: '/v2/scheduling/public/checkMemberFields/{memberId}',
    method: 'GET',
  },
  CAPTURE_OUT_OF_STATE_USER: {
    path: '/v2/scheduling/public/capture/out-of-state',
    method: 'POST',
  },
  BYPASS_PROXY: {
    path: '/report-service-node/bypass-proxy',
    method: 'POST',
  },
  CHECK_EMAIL_PHONE_UNIQUENESS: {
    path: '/auth/emailPhoneUniquenessCheck',
    method: 'POST',
  },
  SAVE_PROVISIONAL_USER: {
    path: '/auth/public/provisionalUser',
    method: 'POST',
  },
  GET_PUBLIC_APPOINTMENT_BY_ID: {
    path: 'v2/scheduling/public/appointment/{appointmentId}',
    method: 'GET',
  },
  GET_APPOINTMENT_BY_ID: {
    path: 'v2/scheduling/appointment/{appointmentId}',
    method: 'GET',
  },
};
