import { AxiosResponse } from 'axios';
import { apiEndpoints } from 'constants/apiEndpoints';
import { baseRequest } from 'utils/httpClient';
import {
  IAppointmentPathParam,
  IAppointmentRequest,
  IRejectAppointmentRequest,
  IGetAppointmentsQueryParams,
  IGetAvailableSlotsBodyRequest,
  IAcceptAppointmentRequest,
} from './appointment.types';

export const getAppointments = (queryParams: IGetAppointmentsQueryParams): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_APPOINTMENTS, undefined, undefined, queryParams);
};

export const getAvailableSlots = (bodyRequest: IGetAvailableSlotsBodyRequest): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_AVAILABLE_SLOTS, undefined, undefined, bodyRequest);
};

export const requestNewAppointment = (payload: IAppointmentRequest): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.CREATE_APPOINTMENT_REQUEST, payload);
};

export const acceptAppointment = (
  requestBody: IAcceptAppointmentRequest,
  pathParams: IAppointmentPathParam
): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.PATCH_APPOINTMENT, requestBody, pathParams);
};

export const rejectAppointment = (
  requestBody: IRejectAppointmentRequest,
  pathParams: IAppointmentPathParam
): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.PATCH_APPOINTMENT, requestBody, pathParams);
};

// Join / Arrive for an appointment
export const joinAppointment = (pathParams: {
  action: string;
  appointmentId: string;
}): Promise<AxiosResponse> => {
  const bodyRequest = pathParams.action;
  const pathParams2 = pathParams.appointmentId;
  return baseRequest(apiEndpoints.JOIN_APPOINTMENT, { action: bodyRequest }, { appointmentId: pathParams2 });
};

// Complete Appointment
export const completeAppointment = (pathParams: {
  action: string;
  appointmentId: string;
}): Promise<AxiosResponse> => {
  const bodyRequest = pathParams.action;
  const pathParams2 = pathParams.appointmentId;
  return baseRequest(
    apiEndpoints.COMPLETE_APPOINTMENT,
    { action: bodyRequest },
    { appointmentId: pathParams2 }
  );
};

export const startOrJoinCall = (pathParams: { channelUrl: string }, queryParams?: any) => {
  return baseRequest(apiEndpoints.START_OR_JOIN_CALL, undefined, pathParams, queryParams ?? undefined);
};

export const getAllProviderServices = (queryParams): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_ALL_UNIQUE_SERVICES, undefined, undefined, queryParams);
};

export const getSpecificService = (serviceId): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_SPECIFIC_SERVICE_PUBLIC, undefined, { serviceId });
};

export const bookAppointmentWithMemberId = (payload: IAppointmentRequest): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.CREATE_APPOINTMENT_WITH_URL_FLOW, payload);
};

export const acceptPublicAppointment = (payload: IAppointmentRequest): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.ACCEPT_PUBLIC_APPOINTMENT, payload);
};

export const fetchInsurance = (state): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.FETCH_INSURANCE, undefined, { state });
};

export const verifyInsurance = (userAccountId): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.VERIFY_INSURANCE, undefined, { userAccountId });
};

export const checkMemberDetails = (memberId): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.CHECK_MEMBER_DETAILS, undefined, { memberId });
};

export const captureOutOfStateUser = (email, state) => {
  return baseRequest(apiEndpoints.CAPTURE_OUT_OF_STATE_USER, { email, state });
};

export const getPublicAppointmentById = (pathParams: { appointmentId: string }): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_PUBLIC_APPOINTMENT_BY_ID, undefined, pathParams);
};

export const getAppointmentById = (pathParams: { appointmentId: string }): Promise<AxiosResponse> => {
  return baseRequest(apiEndpoints.GET_APPOINTMENT_BY_ID, undefined, pathParams);
};
